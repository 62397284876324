import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineArrowBackIosNew } from "react-icons/md"

import HeroView, { HERO_IMAGE } from "../components/hero-view";

export default function HeroCardPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { entry } = state;
  const [getEntry,] = useState(entry);

  return (
    <>
      <a role="button" className=" hero-back-btn" onClick={() => navigate(-1)} ><MdOutlineArrowBackIosNew size={70} /></a>
      <HeroView key={`hero-1`} entry={getEntry} type={HERO_IMAGE.HIGH_RESOLUTION} />
      <HeroView key={`hero-2`} entry={getEntry} type={HERO_IMAGE.CARD} />
    </>
  )
}
