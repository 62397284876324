import React from "react";

import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
} from "react-share";
import { FaFacebook, FaTwitter, FaLinkedin, FaReddit } from "react-icons/fa"


const btnStyle = {
    backgroundColor: "transparent",
    border: "none",
    padding: "10px",
    font: "inherit",
    color: "#eb5646",
    cursor: "pointer"
}

export default function ShareButtons(
    {
        quote,
        shareUrl,
        facebook,
        twitter,
        linked,
        reddit
    }: {
        quote: string,
        shareUrl: string,
        facebook?: boolean;
        twitter?: boolean;
        linked?: boolean;
        reddit?: boolean;
    }) {

    return (
        <div className="social-container d-flex justify-content-center">
            {facebook && <FacebookShareButton url={shareUrl} quote={quote} style={btnStyle}>
                <FaFacebook className="share-icon" />
            </FacebookShareButton>}

            {twitter && <TwitterShareButton style={btnStyle} about="" url={shareUrl} >
                <FaTwitter className="share-icon" />
            </TwitterShareButton>}

            {linked && <LinkedinShareButton style={btnStyle} url={shareUrl} about="">
                <FaLinkedin className="share-icon" />
            </LinkedinShareButton>}

            {reddit && <RedditShareButton style={btnStyle} url={shareUrl} about="">
                <FaReddit className="share-icon" />
            </RedditShareButton>}
        </div>);
}
