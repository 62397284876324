import React from "react";

export default function ErrorMessage({ title, message }: { title: string, message: string }) {
  return (
    <div className='error-page'>
      <div className='error-message'>
        <p style={{ fontSize: "1.5em", 
        fontFamily: "bangers",
          fontWeight: "bold",
          margin: "0.5em 0",
          padding: 0,
          textAlign: "center" 
        }}>{title}</p>
        <p>{message}</p>
        <a className="error-anchor" onClick={() => window.location.reload()}>Click here to get started!</a>
      </div>
    </div>
  );
}
