import React, { useState } from "react";
import { HeroEntry } from "../typescript/super-hero-form";
import Loader from "./atoms/loader";
import ShareButtons from "./share-buttons";
import Image from 'react-bootstrap/Image'


export enum HERO_IMAGE {
    CARD = "aprimo_hero_card_url",
    COMPOSABLE = "aprimo_composable_card_url",
    HIGH_RESOLUTION = "aprimo_high_resolution",
}

export interface HeroViewProps {
    entry: HeroEntry,
    type?: HERO_IMAGE;
    card?: HERO_IMAGE;
    share?: boolean;
    facebook?: boolean;
    twitter?: boolean;
    linked?: boolean;
    reddit?: boolean;
    latestHeroPage?: boolean;
}

export default function HeroView(
    {
        entry,
        type = HERO_IMAGE.CARD,
        facebook,
        twitter,
        linked,
        reddit,
    }: HeroViewProps) {

    const [loading, setLoading] = useState(true);
    const share = facebook || twitter || linked || reddit;

    const imageLoaded = () => {
        setLoading(false)
    }

    if (type === "aprimo_high_resolution" && !entry[type]?.includes("net")) {
        type = HERO_IMAGE.COMPOSABLE;
    }
    
    return (
        <>
            <div
                style={{
                    display: "grid",
                    // justifyContent: "center",
                    overflow: "auto",
                }}
            >
                {loading && <>
                    <Loader />
                    <div style={{
                        maxWidth: "100%",
                        minHeight: "90vh",
                    }} />
                </>}

                {entry?.[type] && <Image style={{width: "100%"}} src={entry[type]} onLoad={imageLoaded} />}

                {share && (<ShareButtons
                    shareUrl={entry[type]}
                    quote="Super Hero"
                    facebook={facebook}
                    twitter={twitter}
                    linked={linked}
                    reddit={reddit}
                />)}
            </div>
        </>);
}
