import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import RenderComponents from "../components/render-components";
import { getPageRes } from "../helper";
import { Entry } from "../typescript/pages";

export default function Landing() {

    const history = useNavigate();
    const [getEntry, setEntry] = useState({} as Entry);

    const [error, setError] = useState(false);

    async function fetchData() {
        try {
            const landing = await getPageRes("/landing");
            (!landing) && setError(true);


            setEntry(landing);

        } catch (error) {
            console.error(error);
            setError(true);
        }
    }

    useEffect(() => {
        fetchData();
        error && history("/404");
    }, [error]);

    return (
        <>
            {Object.keys(getEntry).length ? (
                <RenderComponents
                    pageComponents={getEntry.page_components}
                    blogsPage
                    contentTypeUid='page'
                    entryUid={getEntry.uid}
                    locale={getEntry.locale}
                />
            ) : (
                <Skeleton height={400} />
            )}
        </>
    );




}
