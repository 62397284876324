import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getPageRes } from "../helper";
import RenderComponents from "../components/render-components";
import { Entry } from "../typescript/pages";
import Skeleton from "react-loading-skeleton";


export default function AboutUs() {
  const history = useNavigate();
  const [getEntry, setEntry] = useState({} as Entry);
  const [error, setError] = useState(false);

  async function fetchData() {
    try {
        const aboutUs = await getPageRes("/about-us");
        (!aboutUs) && setError(true);


        setEntry(aboutUs);

    } catch (error) {
        console.error(error);
        setError(true);
    }
  }

  useEffect(() => {
    fetchData();
    error && history("/404");
  }, [error]);

  return (
    <>
      {Object.keys(getEntry).length ? (
        <RenderComponents
          pageComponents={getEntry.page_components}
          blogsPage
          contentTypeUid='page'
          entryUid={getEntry.uid}
          locale={getEntry.locale}
        />
        ) : (
          <Skeleton height={400} />
        )}
    </>
  )
}
