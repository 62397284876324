import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { getMyHeroRes } from "../helper";
import Skeleton from "react-loading-skeleton";
import { HeroEntry } from "../typescript/super-hero-form";
import useLocalStorage from "use-local-storage";
import Loader from "../components/atoms/loader";
import ReactCompareImage from 'react-compare-image';
import StackClient from "../sdk/management";
import HeroView, { HERO_IMAGE } from "../components/hero-view";
import Pusher from 'pusher-js';
import { Stepper, Step, StepLabel, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
const placeholderUrl = "https://via.placeholder.com/468x468?text=generating..."

const PUSHER_API_KEY: any = process.env.REACT_APP_PUSHER_API_KEY;
const PUSHER_CLUSTER: any = process.env.REACT_APP_PUSHER_CLUSTER
const PUSHER_CHANNEL: any = process.env.REACT_APP_PUSHER_CHANNEL;
const PUSHER_EVENT: any = process.env.REACT_APP_PUSHER_EVENT;

export default function MyHeroPage() {

  const navigate = useNavigate();

  const [getEntry, setEntry] = useState({} as HeroEntry);
  const [, setError] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(false)
  const [heroEntry, setHeroEntry] = useLocalStorage<HeroEntry>("heroEntry", {} as HeroEntry);
  const [getData, setGetData] = useState<boolean>(false);
  const [step, setStep] = useState(1);

  let stepProgress: any = window.localStorage.getItem("stepProgress");
  if (stepProgress === null) {
    stepProgress = 0;
  }
  const [progress, setProgress] = useLocalStorage("stepProgress", stepProgress);
  const cacheEntryUid = heroEntry?.uid;

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const buttonProps = () => {
    if (!tablet) return "vertical";
    return "horizontal";
  }

  const alternativeLabel = () => {
    if (!tablet) return false;
    return true;
  }

  useEffect(() => {
    const pusher = new Pusher(PUSHER_API_KEY, {
      cluster: PUSHER_CLUSTER,
    });

    const channel = pusher.subscribe(PUSHER_CHANNEL);
    channel.bind(PUSHER_EVENT, (data: any) => {
      console.info('🚀 ~ file: my-hero.tsx:38 ~ channel.bind ~ data:', data);
      // Update the cache entry UID
      if (data?.entry_uid === cacheEntryUid) {
        if (data?.isStatus) {
          setStep(data?.progress);
          setProgress(data?.progress);
        } else {
          setGetData(true);
        }
      }
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, []);

  async function fetchData(id: string) {
    setLoading(true)
    getMyHeroRes(id)
      .then(result => {
        setEntry(result)
        setHeroEntry(result)
      })
      .catch(err =>
        setError({ error: true, message: err?.error_message })
      )
      .finally(() => setLoading(false))
  }

  const handleGenerate = () => {
    setProgress(0);
    if (cacheEntryUid) {
      setLoading(true)
      StackClient.triggerAutomations(cacheEntryUid)
        .then(() =>
          fetchData(cacheEntryUid))
        .catch(err =>
          setError({ error: true, message: err?.error_message })
        )
        .finally(() => {
          if (loading) setLoading(false)
        })
    }
  }

  const handleAccept = () => {
    setProgress(0);
    if (cacheEntryUid) {
      setLoading(true)
      StackClient.updateEntry(cacheEntryUid, {
        accepted: true
      } as HeroEntry)
        .then(resp => resp?.data?.entry)
        .then(() => {
          fetchData(cacheEntryUid)
        })
        .catch(err =>
          setError({ error: true, message: err?.error_message })
        )
        .finally(() => {
          if (loading) setLoading(false)
        })
    }
  }


  useEffect(() => {
    const hero = Array.from(document.querySelectorAll('div')).find(el => el.innerHTML === 'hero')
    const original = Array.from(document.querySelectorAll('div')).find(el => el.innerHTML === 'original')
    if (hero) {
      hero.style.background = 'red'
      hero.style.fontFamily = 'Bangers';
      hero.style.letterSpacing = '1px';
    }
    if (original) {
      original.style.background = 'red'
      original.style.fontFamily = 'Bangers';
      original.style.letterSpacing = '1px';
    }
  })

  useEffect(() => {
    if (getData) {
      setGetData(false);
    }
    cacheEntryUid && fetchData(cacheEntryUid);
  }, [getData]);

  if (loading)
    return <>
      <Loader />
      <Skeleton className="mt-3" height={100} />
      <Skeleton className="mt-3" height={600} />
    </>

  if (getEntry?.accepted) return (
    <>
      <HeroView key={`hero-1`} entry={getEntry} type={HERO_IMAGE.HIGH_RESOLUTION} facebook linked reddit twitter />
      {/* <HeroView key={`hero-1`} entry={getEntry} type={HERO_IMAGE.COMPOSABLE} facebook linked reddit twitter /> */}
      <HeroView key={`hero-2`} entry={getEntry} type={HERO_IMAGE.CARD} facebook linked reddit twitter />
    </>
  )

  const handleStartOver = () => {
    setProgress(0);
    navigate("/hero-form")
  }

  return (
    <>
      {
        cacheEntryUid && getEntry?.aprimo_hero_card_url === undefined &&
        <div className='hero-page'>
          <div className="progress-container">
            <Stepper activeStep={progress} orientation={buttonProps()} alternativeLabel={alternativeLabel()}>
              <Step>
                <StepLabel>Processing Form</StepLabel>
              </Step>
              <Step>
                <StepLabel>Generating Image</StepLabel>
              </Step>
              <Step>
                <StepLabel>Developing Story</StepLabel>
              </Step>
              <Step>
                <StepLabel>Building Template</StepLabel>
              </Step>
              <Step>
                <StepLabel>Hero Ready!</StepLabel>
              </Step>
            </Stepper>
          </div>
          <div className="hero-message-para">
            <p style={{ fontFamily: "Bangers" }}>Please wait while we spawn your AI-generated Composable Hero!</p>
            <p>This can take up to 45 minutes depending on the number of requests.</p>
            <p>Your image will refresh here when ready.</p>
          </div>
        </div>
      }

      {
        !cacheEntryUid &&
        <div className='hero-page'>
          <div className="hero-message">
            <p style={{ fontFamily: 'Bangers' }}>
              Please fill out the Hero Form
              <Link style={{ color: "#7d4dff" }} to='/hero-form'>
                <span> Hero Form --&gt; </span>
              </Link>
            </p>
          </div>
        </div>
      }

      {
        cacheEntryUid && getEntry?.aprimo_hero_card_url !== undefined &&
        <>
          {!loading && !getEntry?.generating &&
            <div className="note-div">
              {/* <p>Don’t like what you see?</p> */}
              <a className="btn accept-btn mx-2" onClick={handleAccept}>
                <span>Accept</span>
                <span className="tooltiptext">Accept this Composable Hero to retrieve a link</span>
              </a>
              <span>or</span>
              <a className="btn regenerate-btn mx-2" onClick={handleStartOver} role="button">
                <span>Try again</span>
                <span className="tooltiptext">Try Again with a new form and picture
                </span>
              </a>
            </div>
          }

          {getEntry?.generating &&
            <>
              <div className="note-div">
                <p>Please wait while we regenerate your hero. This can take up to 45 minutes.</p>
              </div>
              <div className="progress-container">
                <Stepper activeStep={progress} orientation={buttonProps()} alternativeLabel={alternativeLabel()}>
                  <Step>
                    <StepLabel>Processing Form</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Generating Image</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Developing Story</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Building Template</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Hero Ready!</StepLabel>
                  </Step>
                </Stepper>
              </div>
            </>
          }

          <div className="resizableContainer mt-3">
            {
              heroEntry?.original_image?.url && getEntry?.aprimo_hero_card_url ?
                (<ReactCompareImage
                  aspectRatio="wider"
                  leftImageCss={{ objectFit: "contain", aspectRation: '3/2', }}
                  leftImageLabel="original"
                  leftImage={heroEntry?.original_image?.url}
                  rightImageCss={{ objectFit: "contain", aspectRation: '3/2' }}
                  rightImageLabel={"hero"}
                  rightImage={getEntry?.aprimo_hero_card_url
                    ? getEntry.aprimo_hero_card_url
                    : placeholderUrl} />)
                : (<Skeleton baseColor="#faf5ff" height={500} />)
            }
          </div>
        </>
      }
    </>
  )

}
