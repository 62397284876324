import React from 'react';
import parse from 'html-react-parser';
import { ObjectProps } from "../typescript/section";

export default function LandingWithHtmlCode({ embedObject }: {embedObject : ObjectProps}) {

  return (
    <div className='contact-maps-section max-width'>
        {parse(embedObject.html_code)}
    </div>
  );
}
